@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Nunito:wght@300&display=swap");
* {
  margin: 0 0;
  padding: 0 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

header {
  width: 100%;
}

body {
  font-family: "Nunito", sans-serif;
  overflow-x: hidden;
  background-color: #222831;
  overflow-y: scroll;
}

nav {
  margin: 4% 9%;
  padding: 1% 1%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 50% 50%;
      grid-template-columns: 50% 50%;
  background: rgba(255, 255, 255, 0.25);
  -webkit-box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
          box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border-radius: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

nav .nav-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  position: relative;
}

nav .nav-title .logo-img {
  width: 7rem;
  height: 7rem;
  background: url("../assets/img/me.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  border: 3px inset #32e0c4;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

nav .nav-title .logo-img:hover {
  -webkit-animation: rotateit 1.2s infinite;
          animation: rotateit 1.2s infinite;
}

nav .nav-title .logo-text {
  margin-left: -3rem;
  width: 60%;
  font-family: "Dancing Script", cursive;
  border: medium outset #32e0c4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: x-large;
  font-weight: bolder;
  padding-left: 10%;
  background-color: #222831;
  color: #eeeeee;
}

nav .nav-title .logo-text:hover {
  background-color: #393e46;
  color: #32e0c4;
}

nav .navigation .nav-list {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

nav .navigation .nav-list li {
  -webkit-transform: skew(20deg);
          transform: skew(20deg);
  border-bottom: medium outset #32e0c4;
  border-right: medium inset #32e0c4;
  width: 30%;
  -webkit-transition: all ease-in;
  transition: all ease-in;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}

nav .navigation .nav-list li:hover {
  -webkit-animation: navanime 3s;
          animation: navanime 3s;
}

nav .navigation .nav-list li span {
  text-decoration: none;
  color: #eeeeee;
  display: block;
  font-size: large;
  font-weight: bolder;
  -webkit-transition: all linear;
  transition: all linear;
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
  cursor: pointer;
}

nav .navigation .nav-list li span:hover, nav .navigation .nav-list li span:focus {
  color: #32e0c4;
  font-size: x-large;
}

.rotate-90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.switch {
  position: fixed;
  z-index: 999;
  display: inline-block;
  width: 60px;
  height: 34px;
  top: 50%;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #393e46;
  -webkit-transition: .4s;
  transition: .4s;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #ccc;
}

input:focus + .slider {
  -webkit-box-shadow: 0 0 1px #ccc;
          box-shadow: 0 0 1px #ccc;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 2vh 10%;
  min-height: 60vh;
  background: url("../assets/img/feathers.jpg");
  background-size: cover;
  background-clip: border-box;
  color: #eeeeee;
  -webkit-box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
          box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}

.main .text {
  font-size: x-large;
}

.main .sp-text {
  color: #32e0c4;
}

.main .main-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 10%;
}

.main .main-logo span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.main .main-logo span p {
  margin-top: 3rem;
  height: 30px;
  margin-left: -2rem;
  padding-left: 0;
}

.main .main-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 60%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}

.main .main-text p {
  font-size: 5vh;
}

.main .main-text h2 {
  font-size: 10vh;
}

.main .main-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 30%;
  -webkit-box-pack: right;
      -ms-flex-pack: right;
          justify-content: right;
  position: relative;
}

.main .main-content .main-glass {
  background: rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
          box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin-bottom: 20vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.main .main-content .main-glass p {
  padding: 5% 20%;
  color: #32e0c4;
  font-size: 2rem;
  font-weight: 700;
}

.main .main-content .circle {
  position: absolute;
  width: 5rem;
  height: 5rem;
  background: #32e0c4;
  border-radius: 50%;
}

.main .main-content .circle1 {
  top: 50%;
  right: 45%;
  opacity: 0.5;
}

.main .main-content .circle2 {
  top: 0;
  left: -2%;
  z-index: 1;
  opacity: 0.7;
}

.main .main-content .circle3 {
  top: 20%;
  right: 10%;
  background-color: #eeeeee;
  opacity: 0.7;
  z-index: 1;
}

.main .scrollDownText {
  cursor: pointer;
  -webkit-animation: blinking 3s linear infinite;
          animation: blinking 3s linear infinite;
}

.section-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #393e46;
  position: relative;
  margin: 5% 10%;
}

.section-card h3 {
  color: #eeeeee;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.section-card .main-glass {
  background: rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
          box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 2rem 1rem;
  width: 100%;
}

.section-card .main-glass h2 {
  padding: 0% 20%;
  color: #32e0c4;
  font-size: 2rem;
  font-weight: 700;
  height: 5vh;
}

.section-card .main-glass .multi-carousel {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  margin-top: 2rem;
  overflow-x: hidden;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.section-card .main-glass .multi-carousel .card {
  width: 30%;
}

.section-card .left-arrow {
  top: 50%;
  position: absolute;
  left: 1rem;
  font-size: 3rem;
  color: #eeeeee;
  z-index: 1;
  opacity: 0.7;
  cursor: pointer;
  -webkit-animation: blinking 2s linear infinite;
          animation: blinking 2s linear infinite;
}

.section-card .left-arrow:hover {
  opacity: 1;
  color: #32e0c4;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  box-shadow: -14px 14px 47px -9px #32e0c4;
  -webkit-box-shadow: -14px 14px 47px -9px #32e0c4;
  -moz-box-shadow: -14px 14px 47px -9px #32e0c4;
  -webkit-animation: none;
          animation: none;
  background-color: #eeeeee;
  padding: 0 0;
}

.section-card .right-arrow {
  top: 50%;
  position: absolute;
  right: 1rem;
  font-size: 3rem;
  color: #eeeeee;
  z-index: 1;
  opacity: 0.7;
  cursor: pointer;
  -webkit-animation: blinking 2s linear infinite;
          animation: blinking 2s linear infinite;
}

.section-card .right-arrow:hover {
  opacity: 1;
  color: #32e0c4;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  box-shadow: -14px 14px 47px -9px #32e0c4;
  -webkit-box-shadow: -14px 14px 47px -9px #32e0c4;
  -moz-box-shadow: -14px 14px 47px -9px #32e0c4;
  -webkit-animation: none;
          animation: none;
  background-color: #eeeeee;
  padding: 0 0;
}

.section-card .part-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
}

.section-card .contact-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  min-height: 60vh;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.form .input-control {
  position: relative;
  width: 100%;
  height: 3rem;
  margin-bottom: 1.25rem;
  color: #eeeeee;
}

.form .input-label {
  position: absolute;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: inherit;
  left: 1rem;
  top: 0.75rem;
  padding: 0 0.25rem;
  color: #eeeeee;
  background: transparent;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.form .input-field {
  position: absolute;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: inherit;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 0.75rem 1.25rem;
  z-index: 1;
  border: 2px solid #eeeeee;
  outline: none;
  border-radius: 0.5rem;
  color: var(--color-black);
  background: transparent;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.form .input-field::-webkit-input-placeholder {
  opacity: 0;
  visibility: hidden;
  color: transparent;
}

.form .input-field:-ms-input-placeholder {
  opacity: 0;
  visibility: hidden;
  color: transparent;
}

.form .input-field::-ms-input-placeholder {
  opacity: 0;
  visibility: hidden;
  color: transparent;
}

.form .input-field::placeholder {
  opacity: 0;
  visibility: hidden;
  color: transparent;
}

.form .input-field:focus {
  border: 2px solid #32e0c4;
}

.form .input-field:focus + .input-label {
  font-size: 0.9rem;
  font-weight: 500;
  top: -0.75rem;
  left: 1rem;
  z-index: 5;
  color: #32e0c4;
  background: #222831;
  border-radius: 2px;
}

.form .input-field:not(:placeholder-shown).input-field:not(:focus) + .input-label {
  font-size: 0.9rem;
  font-weight: 500;
  top: -0.80rem;
  left: 1rem;
  z-index: 5;
}

.Maps {
  width: 100%;
  margin-right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 3rem;
  background: rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
          box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin-top: 1rem;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: #eeeeee;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-radius: 2px;
  -webkit-box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
          box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  padding-bottom: 2rem;
  font-size: larger;
}

.card img {
  width: 100%;
  height: 30vh;
  margin: 0 0;
  border: 0;
  border-radius: 2px;
}

.card div {
  margin: 1% 3%;
  width: 100%;
}

.actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.btn {
  padding: 5px 5px;
  margin-top: 2rem;
  background-color: #32e0c4;
  border-radius: 10%;
  font-size: larger;
  color: #222831;
  border: medium solid #222831;
  font-weight: bold;
}

.btn:hover {
  background-color: #222831;
  color: #32e0c4;
  border: medium solid #32e0c4;
  box-shadow: -14px 14px 47px -9px #32e0c4;
  -webkit-box-shadow: -14px 14px 47px -9px #32e0c4;
  -moz-box-shadow: -14px 14px 47px -9px #32e0c4;
  cursor: pointer;
}

.btn p {
  padding: 3px 2px;
}

.message-me {
  position: absolute;
  bottom: 2%;
  right: 5%;
  font-size: 5rem;
  color: #eeeeee;
  opacity: 0.8;
}

.message-me:hover {
  opacity: 1;
}

.social-media {
  position: fixed;
  right: 2%;
  bottom: 35%;
}

.social-media .social-icons {
  margin: 15% 0;
  font-size: 2.5rem;
  opacity: 0.9;
  color: #eeeeee;
  -webkit-transition: all ease-in;
  transition: all ease-in;
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}

.social-media .social-icons:hover {
  opacity: 1;
  font-size: 3rem;
  -webkit-animation: rotateit 1.2s infinite;
          animation: rotateit 1.2s infinite;
}

.social-media .social-icons .social-a-link {
  text-decoration: none;
  color: #32e0c4;
}

.footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: larger;
  font-weight: bold;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  background-color: #222831;
  color: #eeeeee;
}

.fa-cog,
.fa-arrow-circle-right {
  -webkit-animation: rotateit 2s infinite;
          animation: rotateit 2s infinite;
}

.goBackToTop {
  background: transparent;
  width: 5rem;
  height: 5rem;
  position: fixed;
  font-size: 5rem;
  border-radius: 50%;
  border: none;
  color: #32e0c4;
  bottom: 1%;
  right: 2%;
  z-index: 100;
  cursor: pointer;
  -webkit-animation: blinking 4s linear infinite;
          animation: blinking 4s linear infinite;
}

@-webkit-keyframes rotateit {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotateit {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes navanime {
  from {
    border: none;
    border-bottom: thick outset #32e0c4;
  }
  25% {
    background-color: #222831;
  }
  50% {
    border-right: thick outset #32e0c4;
  }
  to {
    width: 25%;
  }
}

@keyframes navanime {
  from {
    border: none;
    border-bottom: thick outset #32e0c4;
  }
  25% {
    background-color: #222831;
  }
  50% {
    border-right: thick outset #32e0c4;
  }
  to {
    width: 25%;
  }
}

@-webkit-keyframes blinking {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.3;
  }
  50% {
    opacity: .5;
  }
  75% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blinking {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.3;
  }
  50% {
    opacity: .5;
  }
  75% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (min-width: 425px) and (max-width: 768px) {
  nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  nav .nav-title {
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  nav .nav-title .logo-text {
    width: 90%;
  }
  nav .navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    margin: 10%;
    z-index: 999;
    margin-bottom: 0;
    background: #222831;
    padding-top: 0.5rem;
    -webkit-box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
            box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(1rem);
    -webkit-backdrop-filter: blur(1rem);
  }
  nav .navigation .nav-list li:hover {
    -webkit-animation: none;
            animation: none;
  }
  nav .navigation .nav-list li span {
    -webkit-transition: none;
    transition: none;
  }
  nav .navigation .nav-list:focus, nav .navigation .nav-list:hover {
    color: #32e0c4;
  }
  nav .nav-list {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .Maps {
    margin-left: 0;
  }
  .section-card .main-glass .multi-carousel .card {
    width: auto;
  }
  .section-card .part-heading {
    font-size: large;
  }
  .main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .main .main-logo span p {
    margin-top: -27rem;
  }
  .main .main-text {
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .main .main-content {
    width: 100%;
  }
  .goBackToTop {
    display: none;
  }
}

@media screen and (max-width: 426px) {
  nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  nav .nav-title {
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  nav .nav-title .logo-text {
    width: 90%;
  }
  nav .navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    margin: 10%;
    z-index: 999;
    margin-bottom: 0;
    background: #222831;
    padding-top: 0.5rem;
    -webkit-box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
            box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(1rem);
    -webkit-backdrop-filter: blur(1rem);
  }
  nav .navigation .nav-list li:hover {
    -webkit-animation: none;
            animation: none;
  }
  nav .navigation .nav-list li span {
    -webkit-transition: none;
    transition: none;
  }
  nav .navigation .nav-list:focus, nav .navigation .nav-list:hover {
    color: #32e0c4;
  }
  nav .nav-list {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .Maps {
    margin-left: 0;
  }
  .section-card .main-glass .multi-carousel .card {
    width: auto;
  }
  .section-card .part-heading {
    font-size: large;
  }
  .main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .main .main-logo span p {
    margin-top: -27rem;
  }
  .main .main-text {
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .main .main-content {
    width: 100%;
  }
  .goBackToTop {
    display: none;
  }
  nav .nav-title .logo-text {
    font-size: medium;
  }
  .message-me {
    right: 2%;
    bottom: 20%;
    font-size: 2.5rem;
    opacity: 0.9;
  }
}
