//variables
$dark: #222831;
$ltdark: #393e46;
$blu: #32e0c4;
$lt: #eeeeee;

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Nunito:wght@300&display=swap');

@mixin mobileresp {
  nav {
    display: flex;
    flex-direction: column;
    // background-color: $lt;
    .nav-title {
      width: 100%;
      align-items: center;
      .logo-text {
        width: 90%;
      }
    }
    .navigation {
      position: fixed;
      bottom: 0;
      width: 100%;
      margin: 10%;
      z-index: 999;
      margin-bottom: 0;
      background: $dark;
      // padding: 1rem 0;
      padding-top: 0.5rem;
      box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
      backdrop-filter: blur( 1rem );
      -webkit-backdrop-filter: blur( 1rem );
      .nav-list{
      li{
        &:hover {
          animation:none;

        }
        span{
          transition: none;
          // font-size: large;
        }
      }
      &:focus,&:hover{
        // font-size: large;
        color: $blu;
        // background-color: #32e0c4;
      }
    }
    }
    .nav-list{
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  .row{
    flex-direction: column;
  }
  .Maps{
    margin-left: 0;
  }
  .section-card{
    .main-glass {
      .multi-carousel{
        .card{
          width: auto;
        }
      }
    }
    .part-heading{
      // display: none;
      font-size: large;
    }
  } 
  .main{
    flex-direction: column-reverse;
    .main-logo{
      span{
        p{
          margin-top: -27rem;
        }
      } 
    } 
    .main-text{
      width: 100%;
      align-items: center;
    }
    .main-content{
      width: 100%;
    }
  } 
  .goBackToTop{
    display: none;
  }
  // .btn--projects {
  //   padding: 20vh 0;
  // }
  // .message-me {
  //   bottom: 4%;
  // }
  // .main {
  //   grid-template-areas: "lfside rtside";
  //   .banner-text {
  //     grid-area: rtside;
  //   }
  //   .bannner {
  //     grid-area: lfside;
  //   }
  // }
}

//default setup
* {
  margin: 0 0;
  padding: 0 0;
  box-sizing: border-box;
}

header {
  width: 100%;
}

//code start
body {
  font-family: "Nunito", sans-serif;
  overflow-x: hidden;
  background-color: $dark;
  overflow-y: scroll;
}
//navigation header
nav {
  margin: 4% 9%;
  padding: 1% 1%;
  display: grid;
  grid-template-columns: 50% 50%;
  // background-color: $lt;
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  // backdrop-filter: blur( 1px );
  // -webkit-backdrop-filter: blur( 5.0px );
  border-radius: 10px;
  // border: 1px solid rgba( 255, 255, 255, 0.18 );
  //   padding-left: 10%;
  align-items: center;
  .nav-title {
    display: flex;
    flex-direction: row;
    position: relative;
    .logo-img {
      //   position: absolute;
      width: 7rem;
      height: 7rem;
      background: url("../assets/img/me.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      border: 3px inset $blu;
      transform: rotate(-45deg);
      &:hover {
        animation: rotateit 1.2s infinite;
      }
    }
    .logo-text {
      //   position: absolute;
      margin-left: -3rem;
      width: 60%;
      font-family: "Dancing Script", cursive;
      border: medium outset $blu;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: x-large;
      font-weight: bolder;
      padding-left: 10%;
      background-color: $dark;
      color: $lt;
      &:hover {
        background-color: $ltdark;
        color: $blu;
      }
    }
  }
  .navigation {
    .nav-list {
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      // background-color: $lt;
      li {
        transform: skew(20deg);
        border-bottom: medium outset $blu;
        border-right: medium inset $blu;
        width: 30%;
        transition: all ease-in;
        transition-delay: 0.1s;
        &:hover {
          animation: navanime 3s;
        }
        // background-color: $lt;
        span {
          text-decoration: none;
          color: $lt;
          display: block;
          font-size: large;
          font-weight: bolder;
          transition: all linear;
          transition-delay: 0.2s;
          cursor: pointer;
          &:hover,
          &:focus {
            color: $blu;
            font-size: x-large;
          }
        }
      }
    }
  }
}
//new main section
.rotate-90{
  transform: rotate(90deg);
}
//light dark slider css
.switch {
  position: fixed;
  z-index: 999;
  display: inline-block;
  width: 60px;
  height: 34px;
  top: 50%;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $ltdark;
  -webkit-transition: .4s;
  transition: .4s;
  transform: rotate(90deg);
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #ccc;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ccc;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


//main styles starts
.main{
  display: flex;
  margin: 2vh 10%;
  min-height: 60vh;
  background: url('../assets/img/feathers.jpg');
  background-size: cover;
  background-clip: border-box;
  color: $lt;
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  .text{
    font-size: x-large;
  }
  .sp-text {
    color: $blu;
  }
  .main-logo{
    // position: relative;
    display: flex;
    width: 10%;
  }
  .main-logo span{
    display: flex;
    p{
    margin-top: 3rem;
    height: 30px;
    margin-left: -2rem;
    padding-left: 0;
      
    }
  }
  .main-text{
    display: flex;
    flex-direction: column;
    width: 60%;
    // margin-left: 20%;
    justify-content: center;
    align-items: baseline;
    p{
      font-size: 5vh;
    }
    h2{
      font-size: 10vh;
    }

  }
  .main-content{
    display: flex;
    width: 30%;
    justify-content: right;
    position: relative;
    // align-items: flex-end;
    .main-glass{
      // background: rgba( 255, 255, 255, 0.25 );
      background: rgba( 0, 0, 0, 0.55 );
      box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
      backdrop-filter: blur( 3px );
      -webkit-backdrop-filter: blur( 3.0px );
      border: 1px solid rgba( 255, 255, 255, 0.18 );
      margin-bottom: 20vh;
      display: flex;
      width: 100%;
      align-items: flex-end;
      p{
        padding: 5% 20%;
        color: $blu;
        font-size: 2rem;
        font-weight: 700;
      }
    }
    .circle{
      position: absolute;
      width: 5rem;
      height: 5rem;
      background: $blu;
      border-radius: 50%;
    }
    .circle1{
      top: 50%;
      right: 45%;
      // z-index: 99;
      opacity: 0.5;
    }
    .circle2{
      top:0;
      left: -2%;
      z-index: 1;
      opacity: 0.7;
    }
    .circle3{
      top: 20%;
      right: 10%;
      background-color: $lt;
      opacity: 0.7;
      z-index: 1;
    }
  }
  .scrollDownText{
    cursor: pointer;
    animation: blinking 3s linear infinite;
  }
  
  
}

.section-card{
  display: flex;
  background: $ltdark;
  position: relative;
  // height: 50vh;
  // width: 100%;
  margin:5% 10%;
  h3{
    color: $lt;
    align-self: center;
  }
  .main-glass{
    // background: rgba( 255, 255, 255, 0.25 );
    background: rgba( 0, 0, 0, 0.55 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 7px );
    -webkit-backdrop-filter: blur( 7.0px );
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    padding: 2rem 1rem;
    // margin-bottom: 20vh;

    width: 100%;
    // align-items: flex-end;
    h2{
      padding: 0% 20%;
      color: $blu;
      font-size: 2rem;
      font-weight: 700;
      height: 5vh;
    }
    .multi-carousel{
      // display: flex;
      display: inline-flex;
      justify-content: space-around;
      margin-top: 2rem;
      overflow-x: hidden;
     flex-wrap: nowrap;
      // width: 100%;
      .card{
        width: 30%;
      }

    }
  }
  .left-arrow{
    top: 50%;
    position: absolute;
    left: 1rem;
    font-size: 3rem;
    color: $lt;
    z-index: 1;
    opacity: 0.7;
    cursor: pointer;
    animation: blinking 2s linear infinite;
    &:hover{
      opacity:1;
      color: $blu;
      border-radius: 50%;
      width: 3rem;
      height: 3rem;
      box-shadow: -14px 14px 47px -9px $blu;
      -webkit-box-shadow: -14px 14px 47px -9px $blu;
      -moz-box-shadow: -14px 14px 47px -9px $blu;
      animation: none;
      background-color: $lt;
      padding: 0 0;
    }
  }
  .right-arrow{
    top: 50%;
    position: absolute;
    right: 1rem;
    font-size: 3rem;
    color: $lt;
    z-index: 1;
    opacity: 0.7;
    cursor: pointer;
    animation: blinking 2s linear infinite;
    &:hover{
      opacity:1;
      color: $blu;
      border-radius: 50%;
      width: 3rem;
      height: 3rem;
      box-shadow: -14px 14px 47px -9px $blu;
      -webkit-box-shadow: -14px 14px 47px -9px $blu;
      -moz-box-shadow: -14px 14px 47px -9px $blu;
      animation: none;
      background-color: $lt;
      padding: 0 0;
    }
  }
  .part-heading{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .contact-form{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 60vh;
    // margin:3px 3px;
    justify-content: space-evenly;
    // align-items: center;
    // .form-group{
    //   display: flex;
    //   flex-direction: column;
    //   width: 100%;
    //   margin: 3px 3px;
    //   position: relative;
    //   input{
    //     width: 100%;
    //     height: 40px;
        // background: transparent;
        // border:none;
        // border-bottom: 2px solid $lt;
        // border-radius: 1px;
        // margin: 3px 3px;
        // display: block;
        // width: 400px;
        // height: 40px;
        // background: transparent;
        // border: solid 1px #ccc;
        // transition: all .3s ease;
        // padding: 0 15px;
        // &::placeholder{
        //   color: $lt;
        //   font-size: 1rem;
        //   color: transparent;
        // }
        // &:focus{

        // }
        
      }
      // label{
        // font-size: large;
        // color: $ltdark;
        // background-color: $blu;
        // border-radius: 2rem;
        // display: flex;
        // justify-content: center;
        // padding: 1px 1px;
        // margin: 3px 3px;
        // position: absolute;
        // cursor: text;
        // z-index: 2;
        // top: 13px;
        // left: 10px;
        // font-size: 12px;
        // font-weight: bold;
        // background: #fff;
      //   padding: 0 10px;
      //   color: #999;
      //   transition: all .3s ease;
      // }
    // }
  // }
}
.form {
  // background-color: $blu;
	.input-control {
		position: relative;
		width: 100%;
		height: 3rem;
		margin-bottom: 1.25rem;
    color: $lt;
	}

	.input-label {
		position: absolute;
		font-family: inherit;
		font-size: 1rem;
		font-weight: 400;
		line-height: inherit;
		left: 1rem;
		top: 0.75rem;
		padding: 0 0.25rem;
		color: $lt;
		background: transparent;
		transition: all 0.3s ease;
	}

	.input-field {
		position: absolute;
		font-family: inherit;
		font-size: 1rem;
		font-weight: 400;
		line-height: inherit;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
		padding: 0.75rem 1.25rem;
		z-index: 1;
		border: 2px solid $lt;
		outline: none;
		border-radius: 0.5rem;
		color: var(--color-black);
		background: transparent;
		transition: all 0.3s ease;

		&::placeholder {
			opacity: 0;
			visibility: hidden;
			color: transparent;
		}

		// Label Floating when Focus
		&:focus {
			border: 2px solid $blu;
			& + .input-label {
				font-size: 0.9rem;
				font-weight: 500;
				top: -0.75rem;
				left: 1rem;
				z-index: 5;
				color: $blu;
        background: $dark;
        border-radius: 2px;
			}
		}

		// Label Keep Floating when not Focus
		&:not(:placeholder-shown).input-field {
			&:not(:focus) {
				& + .input-label {
					font-size: 0.9rem;
					font-weight: 500;
					top: -0.80rem;
					left: 1rem;
					z-index: 5;
          // background: $dark;
				}
			}
		}
	}
}
.Maps{
  width: 100%;
  margin-right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 3rem;
  background: rgba( 0, 0, 0, 0.55 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 7px );
    -webkit-backdrop-filter: blur( 7.0px );
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    margin-top: 1rem;
  // height: 30vh;
}
.row{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.card{
  display: flex;
  flex-direction: column;
  // background-color: $lt;
  color: $lt;
  justify-content: space-between;
  border-radius: 2px;
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  padding-bottom: 2rem;
  font-size: larger;
  // align-items: center;
  // height: 30vh;
  img{
    width: 100%;
    height: 30vh;
    margin: 0 0;
    border: 0;
    border-radius:2px;
  }
  div{
    margin:1% 3%;
    width: 100%;
  }
 
}
.actions{
  display: flex;
  justify-content: space-evenly;
  // position: relative;
}

//for button component
  .btn {
    // position: absolute;
    padding: 5px 5px;
    margin-top: 2rem;
    background-color: $blu;
    border-radius: 10%;
    font-size: larger;
    color: $dark;
    border: medium solid $dark;
    font-weight: bold;
    // width: 120px;
    &:hover {
      background-color: $dark;
      color: $blu;
      border: medium solid $blu;
      box-shadow: -14px 14px 47px -9px $blu;
      -webkit-box-shadow: -14px 14px 47px -9px $blu;
      -moz-box-shadow: -14px 14px 47px -9px $blu;
      cursor: pointer;
    }
    p{
      padding: 3px 2px;
    }
    
  }


//banner start
// section {
//   height: 60vh;
// }
// .main {
//   background-image: linear-gradient(to left, $blu, $ltdark, $dark, $dark);
//   width: 100%;
//   height: 35vh;
//   padding: 4% 10%;
//   display: grid;
//   grid-template-columns: 50% 50%;
//   img {
//     width: 100%;
//     height: 50vh;
//     align-self: center;
//   }
//   .banner-text {
//     color: $lt;
//     .text {
//       font-size: x-large;
//     }
//   }
//   .sp-text {
//     color: $blu;
//   }
//   .btn {
//     padding: 1rem 1rem;
//     margin-top: 2rem;
//     background-color: $blu;
//     border-radius: 10%;
//     font-size: larger;
//     color: $dark;
//     border: none;
//     font-weight: bold;
//     i {
//       font-weight: bolder;
//       font-size: x-large;
//     }
//     &:hover {
//       background-color: $dark;
//       color: $blu;
//       border: medium solid $blu;
//     }
//   }
// }
// footer {
//   width: 100%;
// }
// .projects {
//   margin-left: 0;
//   padding-left: 0;
  //   display: flex;
  //   flex-direction: row;
  //   width: 100%;
  //   height: 100vh;
  // left: 0;
  // position: fixed;
  // top: 0;

  //   top: 40%;
  //   transform: rotate(-90deg);
  //   position: absolute;
  //   left: 0;
  //   top: 40%;
// }

// .btn--projects {
  //   margin: 0;
  // padding: 20vh 1vh;
  //   padding-left: 40vh;
  //   padding-right: 40vh;
  //   padding-bottom: 2rem;
  //   margin-left: -2%;
  // background-color: $lt;
  // border-radius: 8%;
  // font-size: larger;
  // color: $dark;
  // border: medium solid $dark;
  // font-weight: bold;
  //   span {
//   left: 0;
//   position: fixed;
//   top: 30vh;
//   div {
//     transform: rotate(90deg);
//   }
//   &:hover {
//     background-color: $blu;
//     color: $dark;
//     border: medium solid $blu;
//   }
// }

.message-me {
  position: absolute;
  bottom: 2%;
  right: 5%;
  font-size: 5rem;
  color: $lt;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}
.social-media {
  position: fixed;
  right: 2%;
  bottom: 35%;
  .social-icons {
    margin: 15% 0;
    font-size: 2.5rem;
    opacity: 0.9;
    // border: thick solid $dark;
    // border-radius: 50%;
    color: $lt;
    transition: all ease-in;
    transition-delay: 0.2s;
    &:hover {
      opacity: 1;
      font-size: 3rem;
      animation: rotateit 1.2s infinite;
    }
   .social-a-link{
     text-decoration: none;
     color: $blu;
   }
  }
}

.footer {
  display: flex;
  font-size: larger;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: $dark;
  color: $lt;
}
.fa-cog,
.fa-arrow-circle-right {
  animation: rotateit 2s infinite;
}
.goBackToTop{
  background: transparent;
  width: 5rem;
  height: 5rem;
  position: fixed;
  font-size: 5rem;
  border-radius: 50%;
  border: none;
  color: $blu;
  bottom: 1%;
  right: 2%;
  z-index: 100;
  // display: none;
  cursor: pointer;
  animation: blinking 4s linear infinite;
}
//animations
@keyframes rotateit {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes navanime {
  from {
    border: none;
    border-bottom: thick outset $blu;
  }
  25% {
    background-color: $dark;
  }
  50% {
    border-right: thick outset $blu;
  }
  to {
    width: 25%;
  }
}
@keyframes blinking{
  0%{opacity: 0;}
  25%{opacity: 0.3;}
  50%{opacity: .5;}
  75%{opacity: 0.7;}
  100%{opacity: 1;}
}

//mobile responsive
// @media screen and (max-width: 1024px) {
//   .btn--projects {
//     padding-right: 0;
//     padding-left: 0;
//   }
// }
@media screen and (min-width: 425px) and (max-width: 768px) {
  @include mobileresp();
}
@media screen and (max-width: 426px) {
  @include mobileresp();
  // .main .banner-text {
  //   .text {
  //     font-size: medium;
  //   }
  // }
  nav .nav-title .logo-text {
    font-size: medium;
  }
  .message-me {
    right: 2%;
    bottom: 20%;
    font-size: 2.5rem;
    opacity: 0.9;
  }
  // .main {
  //   .btn {
  //     font-size: large;
  //   }
  // }
}
